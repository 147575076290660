import './App.css';
import React, { useState } from "react"
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from './Pages/Home';
import PrivateRoute from './PrivateRoute';
import { AuthContext } from "./context/auth";
import { Login, LoginRedirect } from './Pages/Login'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Billing } from "./Pages/Billing";
import { NavBar } from "./Pages/NavBar";


const stripeKeys = {
  local: 'pk_test_51HzUC4B6E2bzEilJr9gNKmYyIlorVU6TwkAkg5D0hVF82z9x3LQp84muFNJsSwGq5ske8wTEgW7Lqz75lAWYjlPz008Mtv0887',
  development: 'pk_test_51HzUC4B6E2bzEilJr9gNKmYyIlorVU6TwkAkg5D0hVF82z9x3LQp84muFNJsSwGq5ske8wTEgW7Lqz75lAWYjlPz008Mtv0887',
  staging: 'pk_test_51HzUC4B6E2bzEilJr9gNKmYyIlorVU6TwkAkg5D0hVF82z9x3LQp84muFNJsSwGq5ske8wTEgW7Lqz75lAWYjlPz008Mtv0887',
  production: ''
}
const key = stripeKeys[process.env.REACT_APP_ENVIRONMENT]
export const stripePromise = loadStripe(key);

const appUrls = {
  local: 'http://localhost:3000',
  development: 'https://app.dahm7eir.shapescale.com',
  staging: 'https://app.pup.shapescale.com',
  production: 'https://app.shapescale.com'
}

const apiUrls = {
  local: 'http://localhost:8000',
  development: 'https://api.dahm7eir.shapescale.com/api',
  staging: 'https://api.pup.shapescale.com/api',
  production: 'https://api.shapescale.com/api'
}

const keycloakUrls = {
  local: 'http://localhost:8080',
  development: 'https://auth.dahm7eir.shapescale.com',
  staging: 'https://auth.pup.shapescale.com',
  production: 'https://auth.shapescale.com'
}

const apiUrl = apiUrls[process.env.REACT_APP_ENVIRONMENT]
const keyCloakUrl = keycloakUrls[process.env.REACT_APP_ENVIRONMENT]
const appUrl = appUrls[process.env.REACT_APP_ENVIRONMENT]
export const URLS = {
  app: appUrl,
  loginUrl: apiUrl + '/v1/email_login',
  checkoutSessionUrl: apiUrl + '/v1/subscription_checkout',
  customerPortalUrl: apiUrl + '/v1/customer_portal',
  tokensUrl: apiUrl + '/v1/tokens',
  userInfo: apiUrl + '/v1/user',
  billing: apiUrl + '/v1/billing',
  unsubscribe: apiUrl + '/v1/unsubscribe',
  resubscribe: apiUrl + '/v1/resubscribe',
  activationCode: apiUrl + '/v1/activation_code',
  payLastInvoice: apiUrl + '/v1/pay_last_invoice',
  payUnpaidDues: apiUrl + '/v1/pay_unpaid_dues',
  setupCustomer: apiUrl + '/v1/setup_customer',
  keycloakAuth: keyCloakUrl + '/realms/shape/protocol/openid-connect/auth',
  keycloakToken: keyCloakUrl + '/realms/shape/protocol/openid-connect/token'
}
console.log("test")

function App() {

  const existingTokens = JSON.parse(localStorage.getItem("tokens"));
  const [authTokens, setAuthTokens] = useState(existingTokens);
  const setTokens = (data) => {
    if (data) {
      localStorage.setItem("tokens", JSON.stringify(data));
      setAuthTokens(data);
    } else {
      localStorage.removeItem("tokens");
      setAuthTokens(data);
    }

  }


  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <Elements stripe={stripePromise}>
        <Router>
          <div style={{ textAlign: "center", margin: "auto" }}>
            <NavBar />
            <Route exact path="/" component={Home} />
            <Route path="/login" component={Login} />
            <Route path="/login-redirect" component={LoginRedirect} />
            <PrivateRoute path="/billing" component={Billing} />
          </div>
        </Router>
      </Elements>
    </AuthContext.Provider>
  );
}

export default App;
